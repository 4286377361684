define("webapp/locales/it/translations", ["exports", "webapp/locales/it/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Contatta",
    "application.ui.flyout.bag": "Negozio",
    "application.ui.flyout.facebook": "Facebook",
    "application.ui.flyout.xing": "Xing",
    "application.ui.flyout.info": "Info",
    'application.ui.flyout.cog': 'Service',
    'entries.entry.ui.premium': 'Partner Service',
    "entries.entry.ui.eventsPartial": "Programma dell'evento",
    "entries.entry.ui.events.availableSeats": "Posti disponibili",
    "entries.entry.ui.events.bookSeats": "Prenoto in modo vincolante",
    "entries.entry.ui.events.seat": "Posto",
    "entries.entry.ui.events.seats": "Posti",
    'entries.entry.ui.events.coverage': 'Rapporto sulla fiera',
    "entries.entry.ui.events.remainingSeats": "Posti ancora disponibili",
    "entries.entry.ui.events.noSeats": "L'evento è attualmente al completo. Non ci sono più posti disponibili.",

    //auto
    "application.ui.flyout.calendar": "Date attuali",
    "application.ui.flyout.koeppl-stier": "Contatta",
    "application.ui.flyout.koeppl-forrer": "Contatta",
    "application.ui.flyout.mail": "Contatta",
    "application.ui.flyout.koeppl-jobs": "Lavori",
    "application.ui.flyout.koeppl-request": "Richieste",
    "application.ui.flyout.location": "Rivenditori",
    "user.ui.accountToolbarLogin": "Portale del concessionario",
    "user.ui.accountToolbarLoginTooltip": "Registrazione del concessionario",
    "product.navigation.mechanical": "Azionamento meccanico",
    "product.navigation.hydrostatic": "Azionamento idrostatico",
    "product.navigation.electrical": "Azionamento elettrico",
    "product.navigation.mechanicalCarrier": "Veicoli portanti meccanici",
    "product.navigation.hydrostaticCarrier": "Hydrostats",
    "product.navigation.electricalCarrier": "Veicoli portanti elettrici",
    "product.navigation.attachments": "Allegati",
    'product.navigation.accessories': 'Accessori',
    'product.variant.accessories': 'Accessori per la variante selezionata',
    "product.form.title": "Richiesta di prodotto per {{product}}",
    "product.form.noForm": "Per una richiesta di informazioni sui prodotti, usa il nostro modulo di contatto generale nella pagina dei contatti.",
    "entries.entry.ui.products.delivery.red": "Prodotto attualmente non disponibile",
    "entries.entry.ui.products.delivery.yellow": "Prodotto in stock. Possibilmente tempi di consegna più lunghi.",
    "entries.entry.ui.products.delivery.green": "Prodotto in stock in quantità sufficiente",
    'entries.product.ui.netPrizeBusiness': 'Prezzo netto più IVA al tasso legale, più ',
    "entries.entry.ui.jobs": "Posizioni aperte",
    "entries.entry.ui.noJobs": "Attualmente nessun posto vacante (in questa categoria)",
    "entries.entry.ui.initiative": "Vuoi iniziare un'applicazione speculativa?",
    "entries.entry.ui.jobs.open": "Ancora aperto",
    "entries.entry.ui.jobs.assessment": "Nella selezione dei candidati",
    "entries.entry.ui.jobs.closed": "Già occupato",
    "widgets.shoppingCart.wishlist": "Watchlist",
    "job.summary": "Profilo del lavoro",
    "job.summary.contact": "Contatta",
    "job.application.title": "La sua applicazione",
    "job.application.thanks.salutation": "Caro richiedente,",
    "job.application.thanks.title": "Grazie per la sua candidatura e per il suo interesse a fare il prossimo passo di carriera con Bodystreet.",
    "job.application.thanks.content": "Nella fase successiva, elaboreremo i vostri documenti e poi ci metteremo in contatto con voi.",
    "job.application.thanks.contact": "Hai già qualche domanda importante? Allora potete contattarci direttamente.",
    "job.application.thanks.successWish": "Vi auguriamo ogni successo!",
    "job.application.studio": "Studio",
    "job.application.selectRegionCategory": "Seleziona una regione per la quale vuoi fare domanda",
    "job.application.personalData": "I suoi dati personali",
    "job.application.documents": "Applicazione",
    "job.application.privacyTitlePre": "Confermo di aver letto e compreso il",
    "job.application.privacyTitle": "e acconsento al trattamento dei miei dati personali ai fini di",
    "job.application.privacyInfo": "Qualsiasi ulteriore utilizzo dei dati e divulgazione dei miei dati personali a terzi non avrà luogo. Posso revocare questo consenso alla protezione dei dati in qualsiasi momento con effetto per il futuro.",
    "job.application.checkPrivacy": "memorizzati e utilizzati per contattarmi per iscritto, via e-mail o per telefono nell'ambito del processo di candidatura e anche per informarmi su altre offerte di lavoro della Köppl GmbH;",
    "job.application.checkApplicationPool": "memorizzati per un periodo massimo di 6 mesi nell'ambito dell'inclusione nel pool di candidati Köppl, trasmessi a Köppl GmbH e a terzi durante questo periodo e da questi memorizzati e utilizzati per contattarmi per iscritto, via e-mail o per telefono nell'ambito del processo di candidatura e anche per informarmi su altre offerte di lavoro nel sistema Köppl.",
    "job.application.submit": "Invia la domanda ora",
    "job.application.submitNotReady": "Tutti i campi obbligatori devono essere compilati per inviare il modulo.",
    "job.application.coverNote": "Lettera di presentazione",
    "job.application.cv": "Curriculum Vitae",
    "job.application.photo": "Immagine dell'applicazione",
    "job.application.certificate": "Testimonianze",
    "job.application.misc": "Altro",
    "job.application.message": "Nota",
    "job.application.reason": "Perché fai domanda a Köppl?",
    "media.documents.search.placeholder": "Cercare i nomi dei dispositivi, le abbreviazioni dei dispositivi o i numeri di serie.",
    "media.documents.search.categoriesFiltered": "Vengono visualizzati solo i documenti delle seguenti categorie:",
    "media.documents.search.categories": "Utilizzare il filtro di categoria per visualizzare solo i documenti della categoria selezionata.",
    "media.documents.search.languageFilter": "Utilizzare il filtro lingua per visualizzare solo i documenti nella lingua selezionata.",
    "media.documents.search.noResult": "Immettere un termine di ricerca o selezionare una categoria di documenti da visualizzare.",
    "media.documents.search.info": "Si noti che la ricerca è impostata in modo da trovare anche termini o numeri simili. I risultati della ricerca sono ordinati per rilevanza. Ciò significa che i documenti con il maggior numero di corrispondenze si trovano in alto (in alto a sinistra). Quando si seleziona il documento, assicurarsi che il numero di serie sia corretto, soprattutto per gli elenchi dei ricambi e i successivi ordini di ricambi. Se il numero di serie è pertinente, verrà visualizzato insieme al documento."
  });
});