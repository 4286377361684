define('webapp/helpers/tabhelper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var tabCol = params[0] || 1;
    var tabRow = params[1] + 1 || 1;
    var tabMaxRow = params[2] || 0;
    var tabindex = tabCol + tabMaxRow * tabRow;
    return tabindex;
  });
});