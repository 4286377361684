define('webapp/locales/pl/translations', ['exports', 'webapp/locales/pl/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    'application.ui.flyout.cog': 'Serwis',
    'entries.entry.ui.premium': 'Partner Serwis',
    "entries.entry.ui.eventsPartial": "Program imprezy",
    "entries.entry.ui.events.availableSeats": "Dostępne miejsca",
    "entries.entry.ui.events.bookSeats": "Dokonuję wiążącej rezerwacji",
    "entries.entry.ui.events.seat": "Miejsce",
    "entries.entry.ui.events.seats": "Miejsca",
    'entries.entry.ui.events.coverage': 'Raport z targów',
    "entries.entry.ui.events.remainingSeats": "Miejsca wciąż dostępne",
    "entries.entry.ui.events.noSeats": "Wydarzenie jest obecnie w pełni zarezerwowane. Nie ma już wolnych miejsc.",

    // auto
    "application.ui.flyout.receiver": "Kontakt",
    "application.ui.flyout.calendar": "Bieżące daty",
    "application.ui.flyout.koeppl-stier": "Kontakt",
    "application.ui.flyout.koeppl-forrer": "Kontakt",
    "application.ui.flyout.bag": "Sklep",
    "application.ui.flyout.mail": "Kontakt",
    "application.ui.flyout.koeppl-jobs": "Praca",
    "application.ui.flyout.koeppl-request": "Pytania",
    "application.ui.flyout.facebook": "Facebook",
    "application.ui.flyout.xing": "Xing",
    "application.ui.flyout.info": "Info",
    "application.ui.flyout.location": "Dealer",
    "user.ui.accountToolbarLogin": "Portal dealera",
    "user.ui.accountToolbarLoginTooltip": "Rejestracja dealerów",
    "product.navigation.mechanical": "Napęd mechaniczny",
    "product.navigation.hydrostatic": "Napęd hydrostatyczny",
    "product.navigation.electrical": "Napęd elektryczny",
    "product.navigation.mechanicalCarrier": "Mechaniczne pojazdy nośne",
    "product.navigation.hydrostaticCarrier": "Hydrostats",
    "product.navigation.electricalCarrier": "Elektryczne pojazdy transportowe",
    "product.navigation.attachments": "Załączniki",
    'product.navigation.accessories': 'Akcesoria',
    'product.variant.accessories': 'Akcesoria dla wybranego wariantu',
    "product.form.title": "Zapytanie o produkt dla {{product}}",
    "product.form.noForm": "W przypadku pytań dotyczących produktów, prosimy skorzystać z naszego ogólnego formularza kontaktowego na stronie kontaktowej.",
    "entries.entry.ui.products.delivery.red": "Produkt obecnie niedostępny",
    "entries.entry.ui.products.delivery.yellow": "Produkt w magazynie. Możliwie dłuższy czas dostawy.",
    "entries.entry.ui.products.delivery.green": "Produkt jest dostępny w magazynie w wystarczającej ilości",
    'entries.product.ui.netPrizeBusiness': 'Cena netto plus podatek VAT w ustawowej wysokości, plus ',
    "entries.entry.ui.jobs": "Wakaty",
    "entries.entry.ui.noJobs": "Obecnie brak wolnych miejsc (w tej kategorii)",
    "entries.entry.ui.initiative": "Czy chcesz rozpocząć aplikację spekulacyjną?",
    "entries.entry.ui.jobs.open": "Nadal otwarte",
    "entries.entry.ui.jobs.assessment": "Przy wyborze kandydatów",
    "entries.entry.ui.jobs.closed": "Już zajęte",
    "widgets.shoppingCart.wishlist": "Lista obserwacyjna",
    "job.summary": "Profil zawodowy",
    "job.summary.contact": "Kontakt",
    "job.application.title": "Twój wniosek",
    "job.application.thanks.salutation": "Szanowny Wnioskodawco,",
    "job.application.thanks.title": "Dziękujemy za zgłoszenie i zainteresowanie podjęciem kolejnego kroku w karierze w Bodystreet.",
    "job.application.thanks.content": "W następnym kroku opracujemy Twoje dokumenty, a następnie skontaktujemy się z Tobą.",
    "job.application.thanks.contact": "Masz już kilka ważnych pytań? Wtedy możesz skontaktować się z nami bezpośrednio.",
    "job.application.thanks.successWish": "Życzymy Państwu samych sukcesów!",
    "job.application.studio": "Studio",
    "job.application.selectRegionCategory": "Wybierz region, dla którego chcesz złożyć wniosek",
    "job.application.personalData": "Twoje dane osobowe",
    "job.application.documents": "Dokumenty aplikacyjne",
    "job.application.privacyTitlePre": "Potwierdzam, że przeczytałem i zrozumiałem",
    "job.application.privacyTitle": "i wyrażam zgodę na przetwarzanie moich danych osobowych w celu",
    "job.application.privacyInfo": "Dalsze wykorzystywanie danych i udostępnianie moich danych osobowych osobom trzecim nie będzie miało miejsca. Zgodę na ochronę danych osobowych mogę w każdej chwili odwołać ze skutkiem na przyszłość.",
    "job.application.checkPrivacy": "zapisywane i wykorzystywane do pisemnego, mailowego lub telefonicznego kontaktu w ramach procesu aplikacyjnego, a także do informowania mnie o innych ofertach pracy w Köppl GmbH;",
    "job.application.checkApplicationPool": "przechowywane przez okres maksymalnie 6 miesięcy w ramach włączenia do bazy kandydatów Köppl, przekazywane w tym okresie Köppl GmbH i osobom trzecim oraz przechowywane i wykorzystywane przez nie do pisemnego, mailowego lub telefonicznego kontaktu w ramach procesu aplikacyjnego, a także do informowania mnie o innych ofertach pracy w systemie Köppl.",
    "job.application.submit": "Wyślij zgłoszenie teraz",
    "job.application.submitNotReady": "Wszystkie obowiązkowe pola muszą być wypełnione, aby wysłać formularz",
    "job.application.coverNote": "List motywacyjny",
    "job.application.cv": "Curriculum Vitae",
    "job.application.photo": "Obraz zastosowania",
    "job.application.certificate": "Referencje",
    "job.application.misc": "Inne",
    "job.application.message": "Uwaga",
    "job.application.reason": "Dlaczego ubiegasz się o pracę w Köppl?",
    "media.documents.search.placeholder": "Wyszukiwanie nazw urządzeń, skrótów urządzeń lub numerów seryjnych.",
    "media.documents.search.categoriesFiltered": "Wyświetlane są tylko dokumenty z następujących kategorii:",
    "media.documents.search.categories": "Użyj filtra kategorii, aby wyświetlić tylko dokumenty z wybranej kategorii",
    "media.documents.search.languageFilter": "Użyj filtra języka, aby wyświetlić tylko dokumenty w wybranym języku.",
    "media.documents.search.noResult": "Wprowadź wyszukiwane hasło lub wybierz kategorię dokumentów do wyświetlenia.",
    "media.documents.search.info": "Należy pamiętać, że wyszukiwanie jest ustawione tak, aby znajdowane były również podobne terminy lub liczby. Wyniki wyszukiwania są sortowane według trafności. Oznacza to, że dokumenty z największą liczbą dopasowań znajdują się na górze (w lewym górnym rogu). Podczas wybierania dokumentu należy upewnić się, że numer seryjny jest prawidłowy, zwłaszcza w przypadku list części zamiennych i kolejnych zamówień części zamiennych. Jeśli numer seryjny jest prawidłowy, zostanie wyświetlony wraz z dokumentem."
  });
});